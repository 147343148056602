@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package jgweb.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.ExperimentalResourceApi

@ExperimentalResourceApi
private object Drawable0 {
  public val background: DrawableResource by 
      lazy { init_background() }

  public val compose_multiplatform: DrawableResource by 
      lazy { init_compose_multiplatform() }

  public val github: DrawableResource by 
      lazy { init_github() }

  public val gmail: DrawableResource by 
      lazy { init_gmail() }

  public val instagram: DrawableResource by 
      lazy { init_instagram() }

  public val linkedin: DrawableResource by 
      lazy { init_linkedin() }

  public val medium: DrawableResource by 
      lazy { init_medium() }
}

@ExperimentalResourceApi
internal val Res.drawable.background: DrawableResource
  get() = Drawable0.background

@ExperimentalResourceApi
private fun init_background(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:background",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(), "drawable/background.jpg"),
    )
)

@ExperimentalResourceApi
internal val Res.drawable.compose_multiplatform: DrawableResource
  get() = Drawable0.compose_multiplatform

@ExperimentalResourceApi
private fun init_compose_multiplatform(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:compose_multiplatform",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(), "drawable/compose-multiplatform.xml"),
    )
)

@ExperimentalResourceApi
internal val Res.drawable.github: DrawableResource
  get() = Drawable0.github

@ExperimentalResourceApi
private fun init_github(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:github",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(), "drawable/github.svg"),
    )
)

@ExperimentalResourceApi
internal val Res.drawable.gmail: DrawableResource
  get() = Drawable0.gmail

@ExperimentalResourceApi
private fun init_gmail(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:gmail",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(), "drawable/gmail.svg"),
    )
)

@ExperimentalResourceApi
internal val Res.drawable.instagram: DrawableResource
  get() = Drawable0.instagram

@ExperimentalResourceApi
private fun init_instagram(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:instagram",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(), "drawable/instagram.svg"),
    )
)

@ExperimentalResourceApi
internal val Res.drawable.linkedin: DrawableResource
  get() = Drawable0.linkedin

@ExperimentalResourceApi
private fun init_linkedin(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:linkedin",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(), "drawable/linkedin.svg"),
    )
)

@ExperimentalResourceApi
internal val Res.drawable.medium: DrawableResource
  get() = Drawable0.medium

@ExperimentalResourceApi
private fun init_medium(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:medium",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(), "drawable/medium.svg"),
    )
)
